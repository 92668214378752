import React from "react"

import Layout from "../components/Layout"

import MasonryLugares from "../components/MasonryLugares/MasonryLugares"

const Lugares = () => {
  return (
    <Layout>
      <div className="container margin-y-lg">
        <section className="text-component">
          <div className="max-width-sm margin-x-auto">
            <h2 className="text-xxl font-heading font-bold">Lugares</h2>
            <p className="text-sm">Argamasilla de Alba y su entorno</p>
          </div>
        </section>
        <div className="margin-y-lg">
          <MasonryLugares />
        </div>
      </div>
    </Layout>
  )
}

export default Lugares
